import {
  addLot as addLotCall,
  deleteLotImage as deleteLotImageCall,
  updateLot as updateLotCall,
  uploadLotImage as uploadLotImageCall,
} from 'cleaning-lots/controllers/cleaning';
import {
  createSetFiltersAction,
  createSetPageAction,
} from 'shared/helpers/create-set-options-actions';

import { Request } from 'shared/tools/request';
import _ from 'lodash';
import { createAction } from 'redux-actions';
import { createSuccessAction } from 'shared/helpers/create-success-action';
import { getCleaners } from 'cleaning-lots/actions/cleaners';
import { getCurrentLotIndex } from 'cleaning-lots/helpers/get-current-lot-index';
import { getFullAuctionsList } from 'cleaning-lots/actions/admin-cleaning';
import { loadArtistQualifiers } from 'main/actions/artist-qualifier';
import { normalizeRows } from '../normalization/cleaning-schema';
import { notification } from 'antd';
import queryString from 'query-string';
import { getCleaningUiColumns } from 'cleaning-lots/constants/table-columns';

export const setSelectedLots = createAction('SET_SELECTED_LOTS', (selectedIds = []) => ({
  selectedIds,
}));

export const setUnselectedLots = createAction('SET_UNSELECTED_LOTS', (unselectedIds = []) => ({
  unselectedIds,
}));

export const setFilters = createSetFiltersAction('SET_FILTERS_CLEANING');

export const setPage = createSetPageAction('SET_PAGE_CLEANING');

export const setColumns = createAction('SET_SELECTED_COLUMNS', (columns = []) => ({ columns }));

export const setColumnsWithUpdatedFilters = createAction(
  'SET_COLUMNS_WITH_UPDATED_FILTERS',
  (columns = []) => ({ columns }),
);

export const setActiveAuction = createAction('SET_ACTIVE_AUCTION', auctionId => ({ auctionId }));

export const setSortDirection = createAction('SET_SORT_DIRECTION ', sortDir => ({ sortDir }));

export const setSortBy = createAction('SET_SORT_BY ', sortBy => ({ sortBy }));

export const setLotToEdit = createAction('SET_LOT_TO_EDIT ', lotToEdit => ({ lotToEdit }));

export const setBulkEditType = createAction('SET_BULK_EDIT_TYPE', bulkEditType => ({
  bulkEditType,
}));

export const setSelectAll = createAction('SET_SELECT_ALL', isAllSelected => ({ isAllSelected }));

export const setToInitialState = createAction('RAW_LOTS_SET_TO_INITIAL_STATE');

export const getLotsRequest = createAction('RAW_LOTS_REQUEST');
export const getLotsSuccess = createSuccessAction('RAW_LOTS_SUCCESS');
export const getLotsError = createAction('RAW_LOTS_ERROR');

export const setIsLoading = createAction('SET_IS_LOADING ', isLoading => ({ isLoading }));
export const setPendingJobsCount = createAction('SET_PENDING_JOB_COUNT ', pendingJobsCount => ({
  pendingJobsCount,
}));

export const setReviewRequiredReasons = createAction(
  'SET_REVIEW_REQUIRED_REASONS',
  reviewRequiredReasons => ({
    reviewRequiredReasons,
  }),
);

export const setCurrencies = createAction('SET_CURRENCIES', currencies => ({ currencies }));

export const setPriceSold = createAction('SET_PRICE_SOLD', priceSold => ({ priceSold }));

export const setPriceKind = createAction('SET_PRICE_KIND', priceKind => ({ priceKind }));

function getFilterValue(array) {
  if (!array) {
    return undefined;
  }
  if (!array.length) {
    return undefined;
  }
  if (array.includes('None')) {
    array = array.filter(el => el !== 'None');
    array.push(null);
  }
  return array;
}

function generateTableFilters(tableFilters) {
  const tableFiltersRaw = tableFilters || {};

  const keys = Object.keys(tableFiltersRaw);

  const tableFiltersFormatted = {};
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    tableFiltersFormatted[key] = getFilterValue(tableFiltersRaw[key]);
  }

  return tableFiltersFormatted;
}

export const getLots = (lotToEditId = null) => async (dispatch, getState) => {
  try {
    const {
      cleaners,
      user: { userInfo },
      lots,
    } = getState();
    if (!userInfo || !userInfo.getIn) return;
    if (!getState().lots.isLoading) {
      dispatch(getLotsRequest());
    }

    const isAdmin = userInfo.getIn(['role', 'name']) === 'admin';
    if (isAdmin && !cleaners.count) {
      await dispatch(getCleaners());
    }

    const { offset, filters, activeAuctionId, sortDir, sortBy, tableFilters } = lots;

    const params = activeAuctionId
      ? {
        offset,
        lotToEditId,
        ...filters,
        ...generateTableFilters(tableFilters),
        raw_auction_id: [activeAuctionId],
      }
      : {
        offset,
        lotToEditId,
        ...filters,
        ...generateTableFilters(tableFilters),
      };

    const { data } = await Request.post('/api/parsed-lot', {
      data: sortDir
        ? {
          ...params,
          sortBy,
          sortDir,
        }
        : { ...params },
    });

    const {
      entities: { items },
      result: { count, rows },
    } = normalizeRows(data);

    const reviewRequiredReasons = data.reviewRequiredReasons.map(d => ({
      text: `${d.value} (${d.count})`,
      value: d.value,
    }));
    dispatch(setReviewRequiredReasons(reviewRequiredReasons));

    const currencies = data.currencies.map(d => ({
      text: `${d.value} (${d.count})`,
      value: d.value,
    }));
    dispatch(setCurrencies(currencies));

    const priceSold = data.priceSold.map(d => ({
      text: `${d.value} (${d.count})`,
      value: d.value,
    }));
    dispatch(setPriceSold(priceSold));

    const priceKind = data.priceKind.map(d => ({
      text: `${d.value} (${d.count})`,
      value: d.value,
    }));
    dispatch(setPriceKind(priceKind));

    const newColumns = getCleaningUiColumns({
      reviewRequiredReasonsFilter: reviewRequiredReasons,
      currenciesFilter: currencies,
      priceSoldFilter: priceSold,
      priceKindFilter: priceKind,
    });
    dispatch(setColumnsWithUpdatedFilters(newColumns));

    dispatch(getLotsSuccess(items, rows, count, lotToEditId));
  } catch (err) {
    dispatch(getLotsError());
    console.log(err);
    notification.error({
      message: 'Error',
      description: 'Something went wrong.',
    });
  }
};

const queryParamsSelector = state => queryString.parse(state.router.location.search);

const defaultAuctionSelector = state => _.get(state, ['auctions', 'fullList', '0', 'id'], null);

export const loadInitialData = () => async (dispatch, getState) => {
  await dispatch(getFullAuctionsList());

  const { rawAuctionId } = queryParamsSelector(getState());
  // const newActiveAuctionId = rawAuctionId || defaultAuctionSelector(getState());
  dispatch(setActiveAuction(rawAuctionId));

  dispatch(loadArtistQualifiers());
  dispatch(getLots());
  dispatch(getPendingJobsCount());
};

const lotSelector = (id, state) => {
  const { items } = state.lots;

  return items[id];
};

export const updateLotRequest = createAction('UPDATE_LOT_REQUEST');
export const updateLotSuccess = createAction('UPDATE_LOT_SUCCESS');
export const updateLotError = createAction('UPDATE_LOT_ERROR');

export const updateLot = (id, fields) => async (dispatch, getState) => {
  dispatch(updateLotRequest());

  const lot = lotSelector(id, getState());
  const requests = [];

  const { lot_image_url, ...data } = fields;
  const images = lot_image_url.fileList;

  if (images.length && (!lot.lot_image_url || lot.lot_image_url !== images[0].url)) {
    const file = images[0];

    const fileData = new FormData();
    fileData.append('file', file.originFileObj);

    requests.push(uploadLotImageCall(id, fileData));
  }

  if (!images.length && lot.lot_image_url) {
    requests.push(deleteLotImageCall(id));
  }

  try {
    await Promise.all(requests);
    const updateResult = await updateLotCall(id, data);
    dispatch(getLots(id));

    if (!updateResult) {
      dispatch(updateLotError());
      return notification.error({
        message: 'Error',
        description: 'Something went wrong.',
      });
    }

    dispatch(updateLotSuccess());
    notification.success({
      message: 'Success',
      description: 'Lot was successfully updated.',
    });
  } catch (err) {
    dispatch(updateLotError());

    notification.error({
      message: 'Error',
      description: 'Something went wrong.',
    });
  }
};

export const addLotRequest = createAction('ADD_LOT_REQUEST');
export const addLotSuccess = createAction('ADD_LOT_SUCCESS');
export const addLotError = createAction('ADD_LOT_ERROR');

export const addLot = fields => async (dispatch) => {
  dispatch(addLotRequest());
  const requests = [];

  const { lot_image_url, ...data } = fields;
  const images = lot_image_url.fileList;

  try {
    const added = await addLotCall(data);

    if (images.length) {
      const file = images[0];
      const fileData = new FormData();
      fileData.append('file', file.originFileObj);
      requests.push(uploadLotImageCall(added.data.raw_lot_id, fileData));
      await Promise.all(requests);
    }

    dispatch(getLots());
    dispatch(addLotSuccess());

    notification.success({
      message: 'Success',
      description: 'Lot was successfully added.',
    });
  } catch (err) {
    dispatch(addLotError());

    notification.error({
      message: 'Error',
      description: 'Something went wrong.',
    });
  }
};

export const bulkUpdateLotsRequest = createAction('BULK_UPDATE_LOTS_REQUEST');
export const bulkUpdateLotsSuccess = createAction('BULK_UPDATE_LOTS_SUCCESS');
export const bulkUpdateLotsError = createAction('BULK_UPDATE_LOTS_ERROR');

export const bulkUpdateLots = fields => async (dispatch, getState) => {
  dispatch(bulkUpdateLotsRequest());
  try {
    const { selectedIds, unselectedIds, isAllSelected, activeAuctionId, filters, tableFilters } = getState().lots;

    const updateResponse = await Request.put('/api/parsed-lot/bulk', {
      data: {
        ids: isAllSelected ? unselectedIds : selectedIds,
        is_unselected: isAllSelected,
        raw_auction_id: activeAuctionId,
        filters: {
          ...filters,
          ...generateTableFilters(tableFilters),
        },
        ...fields,
      },
    });
    if (!updateResponse) {
      dispatch(bulkUpdateLotsError());
      return notification.error({
        message: 'Error',
        description: 'Something went wrong.',
      });
    }

    dispatch(getLots());

    dispatch(bulkUpdateLotsSuccess());
  } catch (err) {
    dispatch(bulkUpdateLotsError());
  }
};

export const publishLotsRequest = createAction('PUBLISH_LOTS_REQUEST');
export const publishLotsSuccess = createAction('PUBLISH_LOTS_SUCCESS');
export const publishLotsError = createAction('PUBLISH_LOTS_ERROR');

export const publishLots = () => async (dispatch, getState) => {
  dispatch(publishLotsRequest());
  try {
    const { selectedIds, unselectedIds, isAllSelected, activeAuctionId } = getState().lots;

    await Request.put('/api/parsed-lot/publish', {
      data: {
        ids: isAllSelected ? unselectedIds : selectedIds,
        is_unselected: isAllSelected,
        raw_auction_id: activeAuctionId,
      },
    });

    dispatch(getLots());

    dispatch(publishLotsSuccess());
    notification.success({
      message: 'Success',
      description: 'Lots published successfully.',
    });
  } catch (err) {
    dispatch(publishLotsError());
  }
};

export const publishExcludingIgnoredRequest = createAction('PUBLISH_EXCLUDING_IGNORED_REQUEST');
export const publishExcludingIgnoredSuccess = createAction('PUBLISH_EXCLUDING_IGNORED_SUCCESS');
export const publishExcludingIgnoredError = createAction('PUBLISH_EXCLUDING_IGNORED_ERROR');
export const publishExcludingIgnored = () => async (dispatch, getState) => {
  dispatch(publishExcludingIgnoredRequest());
  try {
    const { selectedIds, unselectedIds, isAllSelected, activeAuctionId } = getState().lots;

    const res = await Request.put('/api/parsed-lot/publish-excluding-ignored', {
      data: {
        ids: isAllSelected ? unselectedIds : selectedIds,
        is_unselected: isAllSelected,
        raw_auction_id: activeAuctionId,
      },
    });

    if (res) {
      dispatch(getLots());

      dispatch(publishExcludingIgnoredSuccess());
      notification.success({
        message: 'Success',
        description: 'Lots published successfully.',
      });
    } else {
      dispatch(publishExcludingIgnoredError());
      notification.error({
        message: 'Error',
        description: 'Error on publish.',
      });
    }
  } catch (err) {
    dispatch(publishExcludingIgnoredError());
  }
};

export const unpublishLotsRequest = createAction('UNPUBLISH_LOTS_REQUEST');
export const unpublishLotsSuccess = createAction('UNPUBLISH_LOTS_SUCCESS');
export const unpublishLotsError = createAction('UNPUBLISH_LOTS_ERROR');

export const unpublishLots = () => async (dispatch, getState) => {
  dispatch(unpublishLotsRequest());

  try {
    const { selectedIds, unselectedIds, isAllSelected, activeAuctionId } = getState().lots;

    await Request.put('/api/parsed-lot/unpublish', {
      data: {
        ids: isAllSelected ? unselectedIds : selectedIds,
        is_unselected: isAllSelected,
        raw_auction_id: activeAuctionId,
      },
    });

    dispatch(getLots());

    dispatch(unpublishLotsSuccess());
    notification.success({
      message: 'Success',
      description: 'Lots unpublished successfully.',
    });
  } catch (err) {
    dispatch(unpublishLotsError());
  }
};

export const navigateToPrevLot = () => async (dispatch, getState) => {
  let {
    lots: { lotToEdit, currentPage, ids, items },
  } = getState();

  const currentLotIndex = getCurrentLotIndex(lotToEdit, ids);

  let neededLotId = ids[currentLotIndex - 1];

  if (!neededLotId && currentPage > 1) {
    dispatch(setPage(currentPage - 1));
    await dispatch(getLots());
    ({
      lots: { lotToEdit, currentPage, ids, items },
    } = getState());

    neededLotId = ids[ids.length - 1];
  }
  dispatch(setLotToEdit(items[neededLotId]));
};

export const navigateToNextLot = () => async (dispatch, getState) => {
  let {
    lots: { lotToEdit, currentPage, pagesCount, ids, items },
  } = getState();

  const currentLotIndex = getCurrentLotIndex(lotToEdit, ids);

  let neededLotId = ids[currentLotIndex + 1];

  if (!neededLotId && currentPage < pagesCount) {
    dispatch(setPage(currentPage + 1));
    await dispatch(getLots());
    ({
      lots: { lotToEdit, currentPage, pagesCount, ids, items },
    } = getState());

    [neededLotId] = ids;
  }
  dispatch(setLotToEdit(items[neededLotId]));
};

export const updateAuctionAPRRequest = createAction('UPDATE_AUCTION_APR_REQUEST');
export const updateAuctionAPRSuccess = createAction('UPDATE_AUCTION_APR_SUCCESS');
export const updateAuctionAPRError = createAction('UPDATE_AUCTION_APR_ERROR');

export const updateAuctionAPR = (id, data) => async dispatch => {
  dispatch(updateAuctionAPRRequest());
  try {
    delete data.raw_auction_id;
    setIsLoading(true);
    const resp = await Request.post(`/api/raw-auction/${id}/apr`, { data });

    if (resp.data && resp.data.error) {
      dispatch(updateAuctionAPRError());
    } else {
      dispatch(setIsLoading(false));
      dispatch(updateAuctionAPRSuccess());
    }
  } catch (err) {
    dispatch(updateAuctionAPRError());
  }
};

export const crawlAuctionPrices = (auctionId, selectedIds, unselectedIds, isAllSelected) => async (dispatch, getState) => {
  dispatch(setIsLoading(true));

  const {
    user: { userInfo },
  } = getState();

  const isAdmin = userInfo.getIn(['role', 'name']) === 'admin';
  if (!isAdmin) {
    dispatch(setIsLoading(false));
    return;
  }

  try {
    const { data } = await Request.post('/api/raw-auction/crawl-prices', {
      data: {
        raw_auction_id: auctionId,
        ids: isAllSelected ? unselectedIds : selectedIds,
        is_unselected: isAllSelected,
      },
    });
    dispatch(setIsLoading(false));
    if (data.error) {
      notification.error({
        message: 'Error',
        description: data.error,
      });
    } else {
      notification.success({
        message: 'Success',
        description: 'Crawl prices started',
      });
    }
  } catch (err) {
    dispatch(setIsLoading(false));
    notification.error({
      message: 'Error',
      description: 'Something went wrong',
    });
  }
  dispatch(getPendingJobsCount());
};

export const recrawlAuction = (auctionId, selectedIds, unselectedIds, isAllSelected) => async (dispatch, getState) => {
  dispatch(setIsLoading(true));

  const {
    user: { userInfo },
  } = getState();

  const isAdmin = userInfo.getIn(['role', 'name']) === 'admin';
  if (!isAdmin) {
    dispatch(setIsLoading(false));
    return;
  }

  try {
    const { data } = await Request.post('/api/raw-auction/recrawl-auction', {
      data: {
        raw_auction_id: auctionId,
        ids: isAllSelected ? unselectedIds : selectedIds,
        is_unselected: isAllSelected,
      },
    });
    dispatch(setIsLoading(false));
    if (data.error) {
      notification.error({
        message: 'Error',
        description: data.error,
      });
    } else {
      notification.success({
        message: 'Success',
        description: 'Recrawl Auction Started',
      });
    }
  } catch (err) {
    dispatch(setIsLoading(false));
    notification.error({
      message: 'Error',
      description: 'Something went wrong',
    });
  }
  dispatch(getPendingJobsCount());
};

export const getPendingJobsCount = () => async (dispatch, getState) => {
  dispatch(setIsLoading(true));

  const {
    user: { userInfo },
  } = getState();
  if (!userInfo) return;

  const isAdmin = userInfo.getIn(['role', 'name']) === 'admin';
  if (!isAdmin) {
    return;
  }
  const { data } = await Request.get('/api/raw-auction/pending-job-count');
  dispatch(setPendingJobsCount(data.count));

  dispatch(getLots());

  dispatch(setIsLoading(false));
};

export const setTableFilters = createAction('SET_TABLE_FILTERS ', tableFilters => ({ tableFilters }));
