const AuctionPriority = {
  LOW: 'low',
  MEDIUM: 'medium',
  HIGH: 'high',
};

const AuctionPriorityLabels = {
  [AuctionPriority.LOW]: 'Low',
  [AuctionPriority.MEDIUM]: 'Medium',
  [AuctionPriority.HIGH]: 'High',
};

const AuctionNotesTypes = [
  [null, null, 'None'],
  [false, false, 'Do Not Use APR'],
  [false, true, 'Use APR'],
  [true, null, 'Clean First'],
  [true, false, 'Clean First, Do Not Use APR'],
  [true, true, 'Clean First, Use APR'],
];

const AuctionStatus = {
  UNREVIEWED: 'unreviewed',
  UNPUBLISHED: 'unpublished',
  IN_REVIEW: 'in_review',
  ASSIGNED_TO_CLEAN: 'assigned_to_clean',
  REVIEWED: 'reviewed',
  PUBLISHED: 'published',
  IGNORED: 'ignored',
  ALL_BUT_IGNORED: 'except_ignored',
  ALL: null,
  LEGACY: 'legacy',
};

const AuctionStatusLabels = {
  [AuctionStatus.LEGACY]: 'Legacy',
  [AuctionStatus.UNREVIEWED]: 'Unreviewed',
  [AuctionStatus.UNPUBLISHED]: 'Unpublished',
  [AuctionStatus.IN_REVIEW]: 'In review',
  [AuctionStatus.ASSIGNED_TO_CLEAN]: 'Assigned to clean',
  [AuctionStatus.REVIEWED]: 'Reviewed',
  [AuctionStatus.PUBLISHED]: 'Published',
  [AuctionStatus.IGNORED]: 'Ignored',
  [AuctionStatus.ALL]: 'All',
  [AuctionStatus.ALL_BUT_IGNORED]: 'All but Ignored',
};

const AuctionFlags = {
  ALL: null,
  HIGH_APR: 'high_apr',
  NO_APR: 'no_apr',
  DUPLICATE: 'duplicate',
  CURRENCY: 'currency',
  CLEANER: 'no_cleaner',
  MISSING_ARTISTS: 'missing_artists',
  NO_SALE_PRICES: 'no_sale_prices',
  PRICE_UNAVAILABLE: 'price_unavailable',
  LP_IGNORED_HIGH_PRICE: 'lp_ignored_high_price',
};

const AuctionFlagsLabels = {
  [AuctionFlags.ALL]: 'All',
  [AuctionFlags.HIGH_APR]: 'High % of APR',
  [AuctionFlags.NO_APR]: 'APR not applied',
  [AuctionFlags.DUPLICATE]: 'Duplicate Sale',
  [AuctionFlags.CURRENCY]: 'Multiple Currencies',
  [AuctionFlags.CLEANER]: 'Cleaner Not Assigned',
  [AuctionFlags.MISSING_ARTISTS]: 'Missing Artists',
  [AuctionFlags.NO_SALE_PRICES]: 'No Sale Prices',
  [AuctionFlags.PRICE_UNAVAILABLE]: 'Price Unavailable',
  [AuctionFlags.LP_IGNORED_HIGH_PRICE]: 'LP Ignored High Price',
};

const AuctionHouse = [
  {
    id: 1,
    name: "Christie's",
    order: 1,
  },
  {
    id: 2,
    name: "Sotheby's",
    order: 2,
  },
  {
    id: 3,
    name: 'Bonhams',
    order: 4,
  },
  {
    id: 4,
    name: 'Phillips',
    order: 3,
  },
  {
    id: 5,
    name: 'Dorotheum',
  },
  {
    id: 6,
    name: 'Ketterer',
  },
  {
    id: 7,
    name: 'Swann',
  },
  {
    id: 8,
    name: 'Poly Auction',
  },
  {
    id: 10,
    name: 'Artcurial',
  },
  {
    id: 11,
    name: 'China Guardian',
  },
  {
    id: 18,
    name: 'Lempertz',
  },
  {
    id: 19,
    name: 'Kornfeld',
  },
  {
    id: 20,
    name: 'Bukowskis',
  },
  {
    id: 15,
    name: 'Mainichi',
  },
  {
    id: 21,
    name: 'Farsettiarte',
  },
  {
    id: 22,
    name: 'SBIArt',
  },
  {
    id: 23,
    name: 'Dreweatts',
  },
  {
    id: 24,
    name: 'Forum Auctions',
  },
  {
    id: 25,
    name: 'Koller',
  },
  {
    id: 26,
    name: 'Hindman',
  },
  {
    id: 27,
    name: 'Rago',
  },
  {
    id: 28,
    name: 'Grisebach',
  },
  {
    id: 29,
    name: 'Beaussant',
  },
  {
    id: 30,
    name: 'Heffel',
  },
  {
    id: 31,
    name: 'Seoulauction',
  },
  {
    id: 32,
    name: 'BruunRasmussen',
  },
  {
    id: 34,
    name: 'Tajan',
  },
  {
    id: 35,
    name: 'Doyle',
  },
  {
    id: 36,
    name: 'Pundoles',
  },
  {
    id: 37,
    name: 'Cheffins',
  },
  {
    id: 38,
    name: 'AstaGuru',
  },
  {
    id: 39,
    name: 'SaffronArt',
  },
  {
    id: 40,
    name: 'Lyon & Turnbull',
  },
];

const formattedPriorities = Object.values(AuctionPriority).map((item) => ({
  label: AuctionPriorityLabels[item],
  value: item,
  key: item,
}));

const formattedStatuses = Object.values(AuctionStatus).map((item) => ({
  label: AuctionStatusLabels[item],
  value: item,
  key: item,
}));

const formattedFlags = Object.values(AuctionFlags).map((item) => ({
  label: AuctionFlagsLabels[item],
  value: item,
  key: item,
}));

const formattedAuctionHouses = AuctionHouse.sort((a, b) => {
  if (a.order && b.order) {
    return a.order - b.order;
  }

  if (a.order && !b.order) {
    return -1;
  }

  if (!a.order && b.order) {
    return 1;
  }

  if (a.name > b.name) {
    return 1;
  }

  return -1;
}).map((item) => ({
  label: item.name,
  value: item.name,
  key: item.name,
  id: item.id.toString(),
}));

export {
  AuctionPriority,
  AuctionPriorityLabels,
  AuctionStatus,
  AuctionStatusLabels,
  formattedPriorities,
  formattedStatuses,
  formattedAuctionHouses,
  AuctionNotesTypes,
  AuctionFlags,
  AuctionFlagsLabels,
  formattedFlags,
};
